import { between, maxLength, numeric, required, requiredIf } from 'vuelidate/lib/validators';
import beamlineSpecification from '@/json/beamlineSpecification';
import { is_between, is_no_empty_field } from '@/assets/validators/validator_helpers';

const endStationSpec = beamlineSpecification.end_station_B_specification;

const endStationValidator = {
  valueFromProp: {
    additional_shifts: {},
    detection_modes: { required },
    arpes_photon_energy_from: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('ARPES');
        }
        return false;
      }),
      numeric,
      is_smaller_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('ARPES')) {
          return parseInt(this.valueFromProp.arpes_photon_energy_from, 10)
            <= parseInt(this.valueFromProp.arpes_photon_energy_to, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('ARPES')) {
          return is_between(
            parseInt(this.valueFromProp.arpes_photon_energy_from, 10),
            endStationSpec.arpes.energy_range.energy_range_from,
            endStationSpec.arpes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    arpes_photon_energy_to: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('ARPES');
        }
        return false;
      }),
      numeric,
      is_greater_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('ARPES')) {
          return parseInt(this.valueFromProp.arpes_photon_energy_to, 10)
            >= parseInt(this.valueFromProp.arpes_photon_energy_from, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('ARPES')) {
          return is_between(
            parseInt(this.valueFromProp.arpes_photon_energy_to, 10),
            endStationSpec.arpes.energy_range.energy_range_from,
            endStationSpec.arpes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    sxpes_photon_energy_from: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('SXPES');
        }
        return false;
      }),
      numeric,
      is_smaller_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('SXPES')) {
          return parseInt(this.valueFromProp.sxpes_photon_energy_from, 10)
            <= parseInt(this.valueFromProp.sxpes_photon_energy_to, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('SXPES')) {
          return is_between(
            parseInt(this.valueFromProp.sxpes_photon_energy_from, 10),
            endStationSpec.sxpes.energy_range.energy_range_from,
            endStationSpec.sxpes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    sxpes_photon_energy_to: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('SXPES');
        }
        return false;
      }),
      numeric,
      is_greater_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('SXPES')) {
          return parseInt(this.valueFromProp.sxpes_photon_energy_to, 10)
            >= parseInt(this.valueFromProp.sxpes_photon_energy_from, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('SXPES')) {
          return is_between(
            parseInt(this.valueFromProp.sxpes_photon_energy_to, 10),
            endStationSpec.sxpes.energy_range.energy_range_from,
            endStationSpec.sxpes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    respes_photon_energy_from: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('ResPES');
        }
        return false;
      }),
      numeric,
      is_smaller_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('ResPES')) {
          return parseInt(this.valueFromProp.respes_photon_energy_from, 10)
            <= parseInt(this.valueFromProp.respes_photon_energy_to, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('ResPES')) {
          return is_between(
            parseInt(this.valueFromProp.respes_photon_energy_from, 10),
            endStationSpec.respes.energy_range.energy_range_from,
            endStationSpec.respes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    respes_photon_energy_to: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('ResPES');
        }
        return false;
      }),
      numeric,
      is_greater_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('ResPES')) {
          return parseInt(this.valueFromProp.respes_photon_energy_to, 10)
            >= parseInt(this.valueFromProp.respes_photon_energy_from, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('ResPES')) {
          return is_between(
            parseInt(this.valueFromProp.respes_photon_energy_to, 10),
            endStationSpec.respes.energy_range.energy_range_from,
            endStationSpec.respes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    cdarpes_photon_energy_from: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('CD-ARPES');
        }
        return false;
      }),
      is_smaller_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('CD-ARPES')) {
          return parseInt(this.valueFromProp.cdarpes_photon_energy_from, 10)
            <= parseInt(this.valueFromProp.cdarpes_photon_energy_to, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('CD-ARPES')) {
          return is_between(
            parseInt(this.valueFromProp.cdarpes_photon_energy_from, 10),
            endStationSpec.cdarpes.energy_range.energy_range_from,
            endStationSpec.cdarpes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    cdarpes_photon_energy_to: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('CD-ARPES');
        }
        return false;
      }),
      is_greater_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('CD-ARPES')) {
          return parseInt(this.valueFromProp.cdarpes_photon_energy_to, 10)
            >= parseInt(this.valueFromProp.cdarpes_photon_energy_from, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('CD-ARPES')) {
          return is_between(
            parseInt(this.valueFromProp.cdarpes_photon_energy_to, 10),
            endStationSpec.cdarpes.energy_range.energy_range_from,
            endStationSpec.cdarpes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    spinarpes_photon_energy_from: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('Spin-ARPES');
        }
        return false;
      }),
      is_smaller_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('Spin-ARPES')) {
          return parseInt(this.valueFromProp.spinarpes_photon_energy_from, 10)
            <= parseInt(this.valueFromProp.spinarpes_photon_energy_to, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('Spin-ARPES')) {
          return is_between(
            parseInt(this.valueFromProp.spinarpes_photon_energy_from, 10),
            endStationSpec.spinarpes.energy_range.energy_range_from,
            endStationSpec.spinarpes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    spinarpes_photon_energy_to: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('Spin-ARPES');
        }
        return false;
      }),
      is_greater_than() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('Spin-ARPES')) {
          return parseInt(this.valueFromProp.spinarpes_photon_energy_to, 10)
            >= parseInt(this.valueFromProp.spinarpes_photon_energy_from, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.detection_modes
          && this.valueFromProp.detection_modes.includes('Spin-ARPES')) {
          return is_between(
            parseInt(this.valueFromProp.spinarpes_photon_energy_to, 10),
            endStationSpec.spinarpes.energy_range.energy_range_from,
            endStationSpec.spinarpes.energy_range.energy_range_to,
          );
        }
        return true;
      },
    },
    spinarpes_binding_energy_from: {
      requiredIf: requiredIf(function isBindingEnergyRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('Spin-ARPES');
        }
        return false;
      }),
      is_greater_than() {
        if (this.valueFromProp.detection_modes?.includes('Spin-ARPES')) {
          return (parseFloat(this.valueFromProp.spinarpes_binding_energy_from)
            <= parseFloat(this.valueFromProp.spinarpes_binding_energy_to));
        }
        return true;
      },
    },
    spinarpes_binding_energy_to: {
      requiredIf: requiredIf(function isBindingEnergyRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('Spin-ARPES');
        }
        return false;
      }),
      is_greater_than() {
        if (this.valueFromProp.detection_modes?.includes('Spin-ARPES')) {
          return (parseFloat(this.valueFromProp.spinarpes_binding_energy_from)
            <= parseFloat(this.valueFromProp.spinarpes_binding_energy_to));
        }
        return true;
      },
    },
    spinarpes_required_resolution: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('Spin-ARPES');
        }
        return false;
      }),
      is_greater_than() {
        if (this.valueFromProp.detection_modes?.includes('Spin-ARPES')) {
          return this.valueFromProp.spinarpes_required_resolution >= 0;
        }
        return true;
      },
    },
    spin_resolved: {
      requiredIf: requiredIf(function isRequired() {
        if (this.valueFromProp.detection_modes) {
          return this.valueFromProp.detection_modes.includes('Spin-ARPES');
        }
        return false;
      }),
    },
    photon_polarisation: { required },
    measurement_temperature_from: {
      required,
      numeric,
      is_smaller_than() {
        return parseInt(this.valueFromProp.measurement_temperature_from, 10)
          <= parseInt(this.valueFromProp.measurement_temperature_to, 10);
      },
      between: between(
        endStationSpec.measurement.temperature_range.temperature_range_from,
        endStationSpec.measurement.temperature_range.temperature_range_to,
      ),
    },
    measurement_temperature_to: {
      required,
      numeric,
      is_greater_than() {
        return parseInt(this.valueFromProp.measurement_temperature_to, 10)
          >= parseInt(this.valueFromProp.measurement_temperature_from, 10);
      },
      between: between(
        endStationSpec.measurement.temperature_range.temperature_range_from,
        endStationSpec.measurement.temperature_range.temperature_range_to,
      ),
    },
    cryogenic_liquids: { required },
    lhe_use_justification: {
      requiredIf: requiredIf(function isLHeSelected() {
        if (this.valueFromProp.cryogenic_liquids) {
          return this.valueFromProp.cryogenic_liquids.includes('LHe');
        }
        return false;
      }),
      maxLength: maxLength(300),
    },
    sample_preparation: { required },
    heating: {
      requiredIf: requiredIf(function () {
        if (this.valueFromProp.sample_preparation) {
          return this.valueFromProp.sample_preparation.includes('In situ');
        }
        return false;
      }),
    },
    preparation_temperature_from: {
      requiredIf: requiredIf(function () {
        return this.valueFromProp.heating;
      }),
      numeric,
      is_smaller_than() {
        if (this.valueFromProp.heating) {
          return parseInt(this.valueFromProp.preparation_temperature_from, 10)
            <= parseInt(this.valueFromProp.preparation_temperature_to, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.heating) {
          return is_between(
            parseInt(this.valueFromProp.preparation_temperature_from, 10),
            endStationSpec.sample_preparation.temperature_range.temperature_range_from,
            endStationSpec.sample_preparation.temperature_range.temperature_range_to,
          );
        }
        return true;
      },
    },
    preparation_temperature_to: {
      requiredIf: requiredIf(function () {
        return this.valueFromProp.heating;
      }),
      numeric,
      is_greater_than() {
        if (this.valueFromProp.heating) {
          return parseInt(this.valueFromProp.preparation_temperature_to, 10)
            >= parseInt(this.valueFromProp.preparation_temperature_from, 10);
        }
        return true;
      },
      between_values() {
        if (this.valueFromProp.heating) {
          return is_between(
            parseInt(this.valueFromProp.preparation_temperature_to, 10),
            endStationSpec.sample_preparation.temperature_range.temperature_range_from,
            endStationSpec.sample_preparation.temperature_range.temperature_range_to,
          );
        }
        return true;
      },
    },
    evaporation: { required },
    evaporation_data: {
      requiredIf(val) {
        if (this.valueFromProp.evaporation) return is_no_empty_field(val);
        return true;
      },
    },
    evaporator: {
      requiredIf: requiredIf(function () {
        return this.valueFromProp.evaporation;
      }),
    },
    own_evaporator: {
      requiredIf(val) {
        if (this.valueFromProp.evaporator === 'Own') {
          return is_no_empty_field(val);
        }
        return true;
      },
    },
    ar_sputtering: { required },
    gas_dosing: { required },
    gas_dosing_data: {
      requiredIf(val) {
        if (this.valueFromProp.gas_dosing) {
          return is_no_empty_field(val);
        }
        return true;
      },
    },
    detection_methods: { required },
    inserting_to_chamber: { required },
    vacuum_suitcase: {
      requiredIf: requiredIf(function () {
        if (this.valueFromProp.inserting_to_chamber) {
          return this.valueFromProp.inserting_to_chamber.includes('Vacuum suitcase');
        }
        return false;
      }),
      notEmptyField(val) {
        if (this.valueFromProp.inserting_to_chamber
          && this.valueFromProp.inserting_to_chamber.includes('Vacuum suitcase')
          && val) {
          return is_no_empty_field(val.reduce((acc, el) => acc.concat([Object.values(el)]), []));
        }
        return true;
      },

    },
  },
};

export { endStationValidator };
