import { adaptListOfNamesToMultiselectOptions } from '@/helpers';

const evaporationOptions = {
  first: { prepend: 'Material' },
  second: {
    prepend: 'Thickness',
    append: 'nm',
    type: 'text',
  },
};

const gasDosingOptions = {
  first: { prepend: 'Gas name / Purity' },
  second: {
    prepend: 'Gas amount',
    append: 'cm^3',
    type: 'number',
  },
};

const photonPolarisationOptions = [
  {
    name: 'Linear horizontal',
    buttonVariant: 'outline-info',
  },
  {
    name: 'Circular / Eliptical',
    buttonVariant: 'outline-info',
  },
  {
    name: 'Linear vertical',
    buttonVariant: 'outline-info',
  },
  {
    name: 'Linear skewed',
    buttonVariant: 'outline-info',
  },
];

const detectionModesOptions = [
  {
    name: 'ARPES',
    text: 'Angle Resolved Photoelectron Spectroscopy (ARPES)',
    buttonVariant: 'outline-info',
  },
  {
    name: 'SXPES',
    text: 'Soft X-ray Photoelectron Spectroscopy (SX-PES)',
    buttonVariant: 'outline-info',
  },
  {
    name: 'ResPES',
    text: 'Resonance Photoelectron Spectroscopy (ResPES)',
    buttonVariant: 'outline-info',
  },
  {
    name: 'CD-ARPES',
    text: 'Circular Dichroism ARPES (CD-ARPES)',
    buttonVariant: 'outline-info',
  },
  {
    name: 'In Expert Commissioning Access: Spin-ARPES',
    text: 'In Expert Commissioning Access: Spin and Angle Resolved Photoelectron Spectroscopy (Spin-ARPES)',
    buttonVariant: 'outline-info',
  },
];

const spinARPESResolvedOptions = adaptListOfNamesToMultiselectOptions([
  'Spin-PES (spin-resolved spectra)',
  'Spin-ARPES (spin and angle-resolved PES maps)',
]);

const cryogenicLiquidsOptions = adaptListOfNamesToMultiselectOptions([
  'None', 'LN2', 'LHe',
]);

const samplePreparationOptions = [
  {
    name: 'Cleaving',
    buttonVariant: 'outline-info',
  },
  {
    name: 'Exfoliation',
    buttonVariant: 'outline-info',
  },
  {
    name: 'In situ',
    buttonVariant: 'outline-info',
  },
];

const evaporatorOptions = [
  {
    text: 'Available equipment',
    value: 'Available equipment',
    buttonVariant: 'outline-info',
  },
  {
    text: 'Own',
    value: 'Own',
    buttonVariant: 'outline-info',
  },
];

const ownEvaporatorOptions = {
  first: { prepend: 'Port' },
  second: {
    prepend: 'Length',
    append: 'mm',
    type: 'number',
  },
};

const detectionMethodsOptions = [
  {
    name: 'mini LEED',
    buttonVariant: 'outline-info',
  },
  {
    name: 'Auger',
    buttonVariant: 'outline-info',
  },
  {
    name: 'RGA',
    buttonVariant: 'outline-info',
  },
  {
    name: 'Quarts balance',
    buttonVariant: 'outline-info',
  },
  {
    name: 'None of these',
    buttonVariant: 'outline-info',
  },
];

const insertingToChamberOptions = [
  {
    name: 'Vacuum suitcase',
    buttonVariant: 'outline-info',
  },
  {
    name: 'Load Lock',
    buttonVariant: 'outline-info',
  },
];

const vacuumSuitcaseOptions = {
  first: {
    prepend: 'Type',
    type: 'text',
    model: 'type',
  },
  second: {
    prepend: 'Parameters',
    type: 'text',
    model: 'parameters',
  },
  third: {
    prepend: 'Dimension',
    append: 'mm',
    type: 'text',
    model: 'dimension',
  },
  fourth: {
    prepend: 'Flange',
    type: 'text',
    model: 'flange',
  },
};

export {
  evaporationOptions,
  gasDosingOptions,
  photonPolarisationOptions,
  detectionModesOptions,
  cryogenicLiquidsOptions,
  samplePreparationOptions,
  evaporatorOptions,
  ownEvaporatorOptions,
  detectionMethodsOptions,
  insertingToChamberOptions,
  vacuumSuitcaseOptions,
  spinARPESResolvedOptions,
};
