<template>
  <div>
    <b-card body-bg-variant="light"
            header="Additions to general part"
            header-bg-variant="dark"
            header-text-variant="light">

      <p class="p-form-label">Additional shifts </p>
      <p class="p-form-inline-description"> - Optional</p>
      <p class="p-form-description">
        If you want to make a request for more than maximum shifts allowed, please clarify your claim.
      </p>
      <b-form-textarea v-model="valueFromProp.additional_shifts"
                       :readonly="!editable"
                       :rows="3"
                       placeholder="Enter missing value"/>
    </b-card>

    <b-card body-bg-variant="light"
            header="General information"
            header-bg-variant="dark"
            header-text-variant="light">

      <p class="p-form-label">Detection modes</p>
      <multiselect v-model="valueFromProp.detection_modes"
                   :disabled="!editable"
                   :isInvalid="$v.valueFromProp.detection_modes"
                   :options="detectionModesOptions"
                   :stacked="true"
                   class="detection_modes"/>

      <template v-if="valueFromProp.detection_modes && valueFromProp.detection_modes.length">
        <template v-if="isModeChosenInDetectionModes('In Expert Commissioning Access: Spin-ARPES')">
          <b-alert
            :show="true"
            class="alert"
            variant="danger">
            <h5 class="font-weight-bold">
              Remember that Spin-ARPES is currently available ONLY in Expert Commissioning Access mode.
              For more information visit this
              <a href="https://synchrotron.uj.edu.pl/en_GB/uzytkownicy/nabory-wnioskow"
                 rel="noopener noreferrer"
                 target="_blank">
                website</a>.
            </h5>
          </b-alert>
        </template>

        <energy-range v-for="(mode, idx) in valueFromProp.detection_modes"
                      :key="idx"
                      v-model="valueFromProp"
                      :beamline-specification="endStationSpecification[mode.toLowerCase().replace('-','')]"
                      :disabled="!editable"
                      :is_photon_energy_from_invalid="$v.valueFromProp[mode.toLowerCase().replace('-','')+'_photon_energy_from']"
                      :is_photon_energy_to_invalid="$v.valueFromProp[mode.toLowerCase().replace('-','')+'_photon_energy_to']"
                      :value_from="mode.toLowerCase().replace('-','')+'_photon_energy_from'"
                      :value_to="mode.toLowerCase().replace('-','')+'_photon_energy_to'">
          <slot>for {{ mode }}</slot>
        </energy-range>

        <template v-if="isModeChosenInDetectionModes('Spin-ARPES')">
          <multiselect v-model="valueFromProp.spin_resolved"
                       :disabled="!editable"
                       :isInvalid="$v.valueFromProp.spin_resolved"
                       :options="spinARPESResolvedOptions"/>
          <p class="p-form-label">Binding energy range for Spin-ARPES with respect to the Fermi energy</p>
          <b-row>
            <b-col>
              <b-input-group append="eV"
                             prepend="from">
                <b-form-input v-model="valueFromProp.spinarpes_binding_energy_from"
                              :class="{ 'invalid': $v.valueFromProp.spinarpes_binding_energy_from.$error }"
                              :readonly="!editable"
                              placeholder="Enter missing value"
                              step="0.01"
                              type="number"
                              @input="$v.valueFromProp.spinarpes_binding_energy_from.$touch()"/>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group append="eV"
                             prepend="to">
                <b-form-input v-model="valueFromProp.spinarpes_binding_energy_to"
                              :class="{ 'invalid': $v.valueFromProp.spinarpes_binding_energy_to.$error }"
                              :readonly="!editable"
                              placeholder="Enter missing value"
                              step="0.01"
                              type="number"
                              @input="$v.valueFromProp.spinarpes_binding_energy_to.$touch()"/>
              </b-input-group>
            </b-col>
          </b-row>
          <label for="requiredResolution" style="width: 100%">
            Required resolution
            <b-input-group append="meV">
              <b-form-input id="requiredResolution"
                            v-model="valueFromProp.spinarpes_required_resolution"
                            :class="{ 'invalid': $v.valueFromProp.spinarpes_required_resolution.$error }"
                            :readonly="!editable"
                            min="0"
                            placeholder="Enter missing value"
                            step="0.01"
                            type="number"
                            @input="$v.valueFromProp.spinarpes_required_resolution.$touch()"/>
            </b-input-group>
          </label>
        </template>
        <hr>
      </template>

      <p class="p-form-label">Photon polarization</p>
      <multiselect v-model="valueFromProp.photon_polarisation"
                   :disabled="!editable"
                   :isInvalid="$v.valueFromProp.photon_polarisation"
                   :options="photonPolarisationOptions"/>

      <temperature-range v-model="valueFromProp"
                         :beamline-specification="endStationSpecification.measurement"
                         :disabled="!editable"
                         :value_from_invalid="$v.valueFromProp.measurement_temperature_from"
                         :value_to_invalid="$v.valueFromProp.measurement_temperature_to">
      </temperature-range>

      <p class="p-form-label">Do you want to use cryogenic liquid?</p>
      <multiselect v-model="valueFromProp.cryogenic_liquids"
                   :disabled="!editable"
                   :isInvalid="$v.valueFromProp.cryogenic_liquids"
                   :options="cryogenicLiquidsOptions"
                   unselectBtn="None"/>

      <template v-if="hasProperty(valueFromProp, 'cryogenic_liquids')
                  && valueFromProp.cryogenic_liquids.find(el => el === 'LHe')">
        <p class="p-form-label">Please clarify why do you need liquid helium</p>
        <p class="p-form-description">(max 300 characters incl. spaces)</p>
        <b-form-textarea v-model="valueFromProp.lhe_use_justification"
                      :class="{'invalid': $v.valueFromProp.lhe_use_justification.$error}"
                      placeholder="Enter missing value"
                      @input="$v.valueFromProp.lhe_use_justification.$touch()"/>
      </template>
    </b-card>

    <b-card body-bg-variant="light"
            header="Sample preparation and environment"
            header-bg-variant="dark"
            header-text-variant="light">

      <p class="p-form-label">Sample preparation</p>
      <multiselect v-model="valueFromProp.sample_preparation"
                   :disabled="!editable"
                   :isInvalid="$v.valueFromProp.sample_preparation"
                   :options="samplePreparationOptions"/>

      <template v-if="hasProperty(valueFromProp, 'sample_preparation')
                  && valueFromProp.sample_preparation.find(el => el === 'In situ')">
        <p class="p-form-label">Heating</p>
        <extended-radio-group v-model="valueFromProp.heating"
                              :disabled="!editable"
                              :isInvalid="$v.valueFromProp.heating"
                              :options="yesNo"/>

        <temperature-range v-if="hasProperty(valueFromProp, 'heating') && valueFromProp.heating"
                           v-model="valueFromProp"
                           :beamline-specification="endStationSpecification.sample_preparation"
                           :disabled="!editable"
                           :label_text="'Temperature range'"
                           :value_from="'preparation_temperature_from'"
                           :value_from_invalid="$v.valueFromProp.preparation_temperature_from"
                           :value_to="'preparation_temperature_to'"
                           :value_to_invalid="$v.valueFromProp.preparation_temperature_to"/>
        <hr>
      </template>

      <p class="p-form-label">Evaporation</p>
      <extended-radio-group v-model="valueFromProp.evaporation"
                            :disabled="!editable"
                            :isInvalid="$v.valueFromProp.evaporation"
                            :options="yesNo"/>

      <template v-if="valueFromProp.evaporation">
        <two-data-list v-model="valueFromProp.evaporation_data"
                       :disabled="!editable"
                       :multiselect=true
                       :options="evaporationOptions"/>

        <p class="p-form-label">Evaporator</p>
        <extended-radio-group v-model="valueFromProp.evaporator"
                              :disabled="!editable"
                              :isInvalid="$v.valueFromProp.evaporator"
                              :options="evaporatorOptions"/>

        <two-data-list v-if="valueFromProp.evaporator === 'Own'"
                       v-model="valueFromProp.own_evaporator"
                       :disabled="!editable"
                       :multiselect=false
                       :options="ownEvaporatorOptions"/>
        <hr>
      </template>

      <p class="p-form-label">Ar-sputtering</p>
      <extended-radio-group v-model="valueFromProp.ar_sputtering"
                            :disabled="!editable"
                            :isInvalid="$v.valueFromProp.ar_sputtering"
                            :options="yesNo"/>

      <p class="p-form-label">Gas dosing</p>
      <extended-radio-group v-model="valueFromProp.gas_dosing"
                            :disabled="!editable"
                            :isInvalid="$v.valueFromProp.gas_dosing"
                            :options="yesNo"/>
      <two-data-list v-if="valueFromProp.gas_dosing"
                     v-model="valueFromProp.gas_dosing_data"
                     :disabled="!editable"
                     :options="gasDosingOptions"/>

      <p class="p-form-label">Detection methods</p>
      <multiselect v-model="valueFromProp.detection_methods"
                   :disabled="!editable"
                   :isInvalid="$v.valueFromProp.detection_methods"
                   :options="detectionMethodsOptions"/>

      <p class="p-form-label">Inserting samples into the chamber</p>
      <multiselect v-model="valueFromProp.inserting_to_chamber"
                   :disabled="!editable"
                   :isInvalid="$v.valueFromProp.inserting_to_chamber"
                   :options="insertingToChamberOptions"/>

      <template v-if="valueFromProp.inserting_to_chamber
                  && valueFromProp.inserting_to_chamber.includes('Vacuum suitcase')">

        <p class="p-form-label">Vacuum suitcase</p>
        <three-data-list v-model="valueFromProp.vacuum_suitcase"
                         :disabled="!editable"
                         :options="vacuumSuitcaseOptions"/>

      </template>
    </b-card>
  </div>
</template>

<script>
  import { endStationValidator } from '@/assets/validators/endStationBFormValidator';
  import extendedRadioGroup from '@/components/extended-radio-group';
  import energyRange from '@/components/proposal/proposals/energy-range';
  import temperatureRange from '@/components/proposal/proposals/temperature-range';
  import twoDataList from '@/components/two_data_list';
  import multiselect from '@/components/multiselect';
  import threeDataList from '@/components/three_data_list';
  import beamlineSpecification from '@/json/beamlineSpecification';
  import yesNo from '@/json/radioButtonYesNo';
  import {
    cryogenicLiquidsOptions,
    detectionMethodsOptions,
    detectionModesOptions,
    evaporationOptions,
    evaporatorOptions,
    gasDosingOptions,
    insertingToChamberOptions,
    ownEvaporatorOptions,
    photonPolarisationOptions,
    samplePreparationOptions,
    spinARPESResolvedOptions,
    vacuumSuitcaseOptions,
  } from '@/helpers/proposalForm/endStationB';

  export default {
    name: 'EndStationBForm',
    components: {
      extendedRadioGroup,
      energyRange,
      temperatureRange,
      twoDataList,
      multiselect,
      threeDataList,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
      editable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        valueFromProp: this.value,
        yesNo,
        evaporationOptions,
        gasDosingOptions,
        photonPolarisationOptions,
        detectionModesOptions,
        spinARPESResolvedOptions,
        cryogenicLiquidsOptions,
        samplePreparationOptions,
        evaporatorOptions,
        ownEvaporatorOptions,
        detectionMethodsOptions,
        insertingToChamberOptions,
        vacuumSuitcaseOptions,
        endStationSpecification: beamlineSpecification.end_station_B_specification,
      };
    },
    watch: {
      valueFromProp: {
        handler() {
          this.$emit('input', this.valueFromProp);
          this.$emit('beamline_validation', this.$v.valueFromProp);
        },
        deep: true,
      },
      'valueFromProp.additional_shifts': {
        handler() {
          if (!this.valueFromProp.additional_shifts) {
            delete this.valueFromProp.additional_shifts;
          }
        },
      },
      'valueFromProp.detection_modes': {
        handler() {
          this.detectionModesOptions
            .forEach((option) => {
              if (!this.valueFromProp.detection_modes.includes(option.name)) {
                delete this.valueFromProp[`${option.name.toLowerCase().replace('-', '')}_photon_energy_from`];
                delete this.valueFromProp[`${option.name.toLowerCase().replace('-', '')}_photon_energy_to`];
              }
            });
          if (!this.isSpinARPESDetectionModeSelected()) {
            delete this.valueFromProp.spin_resolved;
            delete this.valueFromProp.spinarpes_binding_energy_from;
            delete this.valueFromProp.spinarpes_binding_energy_to;
            delete this.valueFromProp.spinarpes_required_resolution;
          }
          if (this.valueFromProp.detection_modes.length === 0) {
            delete this.value.detection_modes;
          }
        },
      },
      'valueFromProp.cryogenic_liquids': {
        handler() {
          if (!this.isLHeChecked()) {
            delete this.valueFromProp.lhe_use_justification;
          }
        },
      },
      'valueFromProp.evaporation': {
        handler() {
          if (!this.valueFromProp.evaporation) {
            if (this.valueFromProp.evaporation_data) {
              delete this.valueFromProp.evaporation_data;
            }
            if (this.valueFromProp.evaporator) {
              delete this.valueFromProp.evaporator;
            }
          }
        },
      },
      'valueFromProp.evaporator': {
        handler() {
          if (!(this.valueFromProp.evaporator === 'Own') && this.valueFromProp.own_evaporator) {
            delete this.valueFromProp.own_evaporator;
          }
        },
      },
      'valueFromProp.gas_dosing': {
        handler() {
          if (!this.valueFromProp.gas_dosing && this.valueFromProp.gas_dosing_data) {
            delete this.valueFromProp.gas_dosing_data;
          }
        },
      },
      'valueFromProp.heating': {
        handler() {
          if (!this.valueFromProp.heating) {
            if (this.valueFromProp.preparation_temperature_from) {
              delete this.valueFromProp.preparation_temperature_from;
            }
            if (this.valueFromProp.preparation_temperature_to) {
              delete this.valueFromProp.preparation_temperature_to;
            }
          }
        },
      },
      'valueFromProp.inserting_to_chamber': {
        handler() {
          if (!this.valueFromProp.inserting_to_chamber.includes('Vacuum suitcase')
            && this.valueFromProp.vacuum_suitcase) {
            delete this.valueFromProp.vacuum_suitcase;
          }
        },
      },
      'valueFromProp.sample_preparation': {
        handler() {
          if (!this.valueFromProp.sample_preparation.includes('In situ')
            && this.valueFromProp.heating) {
            delete this.valueFromProp.heating;

            if (this.valueFromProp.preparation_temperature_from) {
              delete this.valueFromProp.preparation_temperature_from;
            }
            if (this.valueFromProp.preparation_temperature_to) {
              delete this.valueFromProp.preparation_temperature_to;
            }
          }
        },
      },
      'valueFromProp.spin_resolved': {
        handler() {
          if (!this.valueFromProp.spin_resolved) {
            delete this.valueFromProp.spin_resolved;
          }
        },
      },
      'valueFromProp.spinarpes_binding_energy_from': {
        handler() {
          if (!this.valueFromProp.spinarpes_binding_energy_from) {
            delete this.valueFromProp.spinarpes_binding_energy_from;
          }
        },
      },
      'valueFromProp.spinarpes_binding_energy_to': {
        handler() {
          if (!this.valueFromProp.spinarpes_binding_energy_to) {
            delete this.valueFromProp.spinarpes_binding_energy_to;
          }
        },
      },
      'valueFromProp.spinarpes_required_resolution': {
        handler() {
          if (!this.valueFromProp.spinarpes_required_resolution) {
            delete this.valueFromProp.spinarpes_required_resolution;
          }
        },
      },
    },
    created() {
      this.$emit('beamline_validation', this.$v.valueFromProp);
    },
    methods: {
      isModeChosenInDetectionModes(mode) {
        return this.valueFromProp.detection_modes.includes(mode);
      },
      isSpinARPESDetectionModeSelected() {
        if (this.valueFromProp.detection_modes.length !== 0) {
          return this.valueFromProp.detection_modes.includes('Spin-ARPES');
        }
        return false;
      },
      isLHeChecked() {
        return this.valueFromProp.cryogenic_liquids
          && this.valueFromProp.cryogenic_liquids.includes('LHe');
      },
    },
    validations: endStationValidator,
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }

  .detection_modes {
    margin-bottom: 25px;
  }
</style>
